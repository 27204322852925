@keyframes pre-progress
	0%
		background-position: 0 0
	100%
		background-position: -35px -35px

@-webkit-keyframes pre-progress
	0%
		background-position: 0 0
	100%
		background-position: -35px -35px

.pre-error
	flex: 1
	color: #721c24

	&-loading,
	&-content
		top: 0px
		left: 0px
		width: 100%
		height: 100%
		position: absolute

		display: flex
		align-items: center
		justify-content: center
		flex-direction: column

		backface-visibility: hidden
		transition: transform 0.3s
		transform-style: preserve-3d
	
	&.content &-content,
	&.loading &-loading
		transform: rotateY(0deg)
	&:not(.content) &-content,
	&:not(.loading) &-loading
		transform: rotateY(180deg)

	.icon
		width: 256px
		height: 256px

	.content
		padding: 24px
		max-width: 60%

	@media screen and (max-width:800px)
		.icon
			width: 196px
			height: 196px
		.content
			max-width: 80%
	@media screen and (max-width:600px)
		.icon
			width: 128px
			height: 128px
		.content
			max-width: 100%

	.button
		border-radius: 0px
		cursor: pointer	
		transition: background-color 0.3s

		&>span
			margin: 6px

	.progress
		width: 333px
		max-width: 90vw
		height: 36px
		margin: 24px
		border: 3px solid #721c24
		border-radius: 24px
		background-image: linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)
		background-size: 35px 35px
		background-color: #721c24
		animation: pre-progress 2s linear infinite
		background-repeat: repeat