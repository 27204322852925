html, body, #root
	width: 100%
	min-height: 100%
	margin: 0px
	padding: 0px
#root 
	position: absolute
	top: 0
	left: 0
	background: #fafafa
	z-index: 1000
	display: flex
	
@keyframes page-loaded
	from
		opacity: 1
		transform: scale(1)
	to
		opacity: 0
		transform: scale(1.8)

html.loaded
	&::after
		animation: page-loaded .2s linear 1 forwards

	> body > #loader
		animation: page-loaded .2s linear 1 forwards
